export const check_login = (router) => {
  if (localStorage.getItem('login')) {
    return true;
  } else {
    if (
      router.asPath.includes('/a/my-account') ||
      router.asPath.includes('/a/add-listing') ||
      router.asPath.includes('/orders') ||
      router.asPath.includes('/a/edit-account') ||
      router.asPath.includes('/a/create-account') ||
      router.asPath.includes('/a/edit-listing') ||
      router.asPath.includes('/edit-profile') ||
      router.asPath.includes('/payout') ||
      router.asPath.includes('/checkout') ||
      router.asPath.includes('/payment') ||
      router.asPath.includes('/review') ||
      router.asPath.includes('/transactions')
    ) {
      router.push(`/sign-in?to=/`);
      return false;
    } else {
      router.push(`/sign-in?to=${router.asPath}`);
      return false;
    }
  }
};
