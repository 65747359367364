import Link from 'next/link';
import React from 'react';

const ALink = ({ children, href, isEmbed, target }) => {
  return isEmbed ? (
    <a href={href} target={target ? target : '_blank'} rel="noreferrer">
      {children}
    </a>
  ) : (
    <Link href={href}>{children}</Link>
  );
};

export default ALink;
